import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'

export type UserState = {
    avatar?: string
    userName?: string
    email?: string
    authority?: string[]
    entityId?: number
    extraEntityId?:number
    extraEntityName?:string
}

const initialState: UserState = {
    avatar: '',
    userName: '',
    email: '',
    authority: [],
    entityId: undefined,
    extraEntityId: undefined,
    extraEntityName:'',

}

const userSlice = createSlice({
    name: `${SLICE_BASE_NAME}/user`,
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<UserState>) {
            state.avatar = action.payload?.avatar
            state.email = action.payload?.email
            state.userName = action.payload?.userName
            state.authority = action.payload?.authority?.map(val=> val.toLowerCase())
            state.entityId = action.payload?.entityId
            state.extraEntityId = action.payload?.extraEntityId
            state.extraEntityName = action.payload?.extraEntityName
        },
        setExtraEntity(state, action: PayloadAction<number>) {
            state.extraEntityId = action.payload?.extraEntityId
            state.extraEntityName = action.payload?.extraEntityName
        }
    },
})

export const { setUser, setExtraEntity } = userSlice.actions
export default userSlice.reducer
