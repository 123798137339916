import { createSlice } from "@reduxjs/toolkit";

export interface PaginationState {
    [key: string]: {
        currentPage: number;
        pageSize: number;
    }
}

const initialState: PaginationState = {
    manageCompanies: {
        currentPage: 0,
        pageSize: 10,
    },
    manageItems: {
        currentPage: 0,
        pageSize: 10,
    },
    manageRoles: {
        currentPage: 0,
        pageSize: 10,
    },
    manageSuppliers: {
        currentPage: 0,
        pageSize: 10,
    },
    manageManufacturers: {
        currentPage: 0,
        pageSize: 10,
    },
    manageProcesses: {
        currentPage: 0,
        pageSize: 10,
    },
};

const paginationSlice = createSlice({
    name: "pagination",
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            const { pageKey, currentPage } = action.payload;
            if (state[pageKey]) {
                state[pageKey].currentPage = currentPage;
            }
        },
        setPageSize: (state, action) => {
            const { pageKey, pageSize } = action.payload;
            if (state[pageKey]) {
                state[pageKey].pageSize = pageSize;
            }
        },
        resetPagination: (state, action) => {
            const { pageKey } = action.payload;
            if (state[pageKey]) {
                state[pageKey].currentPage = 0;
                state[pageKey].pageSize = 10; // Reset to default values
            }
        }
    }
})

export const { setCurrentPage, setPageSize, resetPagination } = paginationSlice.actions;

export default paginationSlice.reducer;
