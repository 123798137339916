import { createApi } from '@reduxjs/toolkit/query/react'
import BaseService from './BaseService'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import type { AxiosRequestConfig, AxiosError } from 'axios'
import { FinalProductData } from '@/@types/lca'

const axiosBaseQuery =
    (): BaseQueryFn<
        {
            url: string
            method: AxiosRequestConfig['method']
            data?: AxiosRequestConfig['data']
            params?: AxiosRequestConfig['params']
        },
        unknown,
        unknown
    > =>
        async (request) => {
            try {
                const response = BaseService(request)
                return response
            } catch (axiosError) {
                const err = axiosError as AxiosError
                return {
                    error: {
                        status: err.response?.status,
                        data: err.response?.data || err.message,
                    },
                }
            }
        }

const RtkQueryService = createApi({
    reducerPath: 'rtkApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        getMaterials: builder.query({
            query: () => ({
                url: '/v1/raw-materials',
                method: 'GET',
            }),
        }),
        getProcess: builder.query({
            query: () => ({
                url: '/v1/process',
                method: 'GET',
            })
        }),
        getSuppliers: builder.query({
            query: () => ({
                url: '/v1/suppliers',
                method: 'GET',
            })
        }),
        getSuppliersByEntityId: builder.query({
            query: (id) => ({
                url: `/v1/supplier-mapping/${id}`,
                method: 'GET',
            })
        }),
        getManufacturers: builder.query({
            query: () => ({
                url: '/v1/manufacturers',
                method: 'GET'
            }),
        }),
        getManufacturersByEntityId: builder.query({
            query: (id) => ({
                url: `/v1/manufacturer-mapping/${id}`,
                method: 'GET'
            }),
        }),
        getProcessByEntityId: builder.query({
            query: (id) => ({
                url: `/v1/process-mapping/${id}`,
                method: 'GET'
            }),
        }),
        getMaterialsByEntityId: builder.query({
            query: (id) => ({
                url: `/v1/raw-materials-mapping/${id}`,
                method: 'GET'
            }),
        }),
        getMaterialEmissionByLcaId: builder.query({
            query: (lcaId:number) => ({
                url: `/v1/entity-material/lca/${lcaId}`,
                method: 'GET'
            }),
        }),
        getProcessEmissionByLcaId: builder.query({
            query: (lcaId:number) => ({
                url: `/v1/entity-process/lca/${lcaId}`,
                method: 'GET'
            }),
        }),
        getWasteGHGEmissionByLcaId: builder.query({
            query: (lcaId:number) => ({
                url: `/v1/entity-waste-ghg/lca/${lcaId}`,
                method: 'GET'
            }),
        }),
        getLcaById:builder.query({
            query: (lcaId:number) => ({
                url: `/v1/lca/${lcaId}`,
                method: 'GET'
            }),
        })
    }),
})

export const {
    useGetMaterialsQuery,
    useGetProcessQuery,
    useGetSuppliersQuery,
    useGetManufacturersByEntityIdQuery,
    useGetSuppliersByEntityIdQuery,
    useGetMaterialsByEntityIdQuery,
    useGetProcessByEntityIdQuery,
    useGetManufacturersQuery,
    useGetLcaByIdQuery,
    useGetMaterialEmissionByLcaIdQuery,
    useGetProcessEmissionByLcaIdQuery,
    useGetWasteGHGEmissionByLcaIdQuery
} = RtkQueryService

export default RtkQueryService
