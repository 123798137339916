export const signInUserData = [
    {
        id: '1',
        avatar: '/img/avatars/thumb-1.jpg',
        userName: 'kv',
        email: 'vishalsingh.vs519@gmail.com',
        authority: ['admin', 'user'],
        password: 'admin',
        accountUserName: 'KV',
    },
    {
        id: '2',
        avatar: '/img/avatars/thumb-1.jpg',
        userName: 'admin',
        email: 'admin@gmail.com',
        authority: ['admin'],
        password: 'admin',
        accountUserName: 'admin',
    },
    {
        id: '3',
        avatar: '/img/avatars/thumb-1.jpg',
        userName: 'user',
        email: 'user@gmail.com',
        authority: ['user'],
        password: 'user',
        accountUserName: 'user',
    },
]
